import React, { useEffect } from "react";
import { Text } from "hrm_ui_lib/components/Text";
import { Divider } from "hrm_ui_lib/components/Divider";
import { CycleTable, SetCycleButton } from "components";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { fetchCycleData } from "store/actions";

export const SettingsView = () => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchCycleData();
  }, []);

  return (
    <div className="settings_view">
      <Text weight="bold" size="large" className="mb-16">
        {t("vp_cycle_main_title")}
      </Text>
      <Divider type="primary" isHorizontal className="title_divider" />
      <div className="table_topbar pt-8">
        <SetCycleButton />
      </div>
      <CycleTable />
    </div>
  );
};
