import React from "react";
import { v1 as uuidv1 } from "uuid";
import { ViewInfoField } from "../../common";
import { useContainerData } from "../../../hooks";
import { FORM_FIELD_LABEL, FORM_FIELD_NAME } from "../consts";
import { Divider } from "hrm_ui_lib/components/Divider";
import { TCompany } from "utils/types";
import { getNameFromLocalization, getStringWidth } from "utils/helpers";
import { getExcludeUnitLabels } from "../helpers";

export const PlanningDetailsView = () => {
  const cycleDetails = useContainerData("activeCycleData");
  const companies = useContainerData("companies");

  if (!cycleDetails) {
    return null;
  }
  const { unitId } = cycleDetails || {};
  const company = companies.find(
    (company: TCompany) => company.value === unitId
  );

  return (
    <>
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.company]}
        description={getNameFromLocalization(company.labels)}
      />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.planningYear]}
        description={cycleDetails.planningYear}
      />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.mandatoryDays]}
        description={cycleDetails.mandatoryDays}
      />
      <Divider isHorizontal type="primary" />
      <ViewInfoField
        fullWidth
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeUnits]}
        description={getExcludeUnitLabels(cycleDetails.unitExclusions)}
        tooltip={
          getStringWidth(
            getExcludeUnitLabels(cycleDetails.unitExclusions),
            14
          ) > 400
            ? {
                text: getExcludeUnitLabels(cycleDetails.unitExclusions),
                id: uuidv1(),
              }
            : undefined
        }
        infoClassName={`text-truncate exclude_section`}
      />
    </>
  );
};
