import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import { getTranslationFileName, loadTranslations } from "i18n";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

loadTranslations(getTranslationFileName()).then(() => {
  root.render(<App />);
});
