import { v1 as uuidv1 } from "uuid";
import { MESSAGE_TYPES, RESPONSE_STATUSES } from "utils/enums";
import {
  ACTIVE_LANG_CODE,
  CYCLE_STATUSES,
  ROUTE_CONFIGS,
} from "utils/constants";
import { notify } from "hrm_ui_lib/components/Snackbar";
import {
  TCompany,
  TCycleStatus,
  TExcludedAssociate,
  TExcludedUnit,
  TLocaliziedCompanyName,
  TLocaliziedEmployeeName,
  TLocaliziedUnitName,
  TSelectOptions,
} from "utils/types";
import dayjs from "dayjs";

export const checkAuthorization = (
  responseStatus: RESPONSE_STATUSES
): boolean => {
  if (responseStatus === RESPONSE_STATUSES.UNAUTHORIZED_STATUS_CODE) {
    // Todo temporary solution for check authorization
    fetch("/Core/auth/session", {
      headers: { ServiceName: "Core" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.isAuthenticated) {
          window.location.href = ROUTE_CONFIGS.authorization.logout;
        } else {
          window.location.href = `${ROUTE_CONFIGS.authorization.login}?returnUrl=${window.location.pathname}`;
        }
      })
      .catch(() => {
        window.location.href = `${ROUTE_CONFIGS.authorization.login}?returnUrl=${window.location.pathname}`;
      });
    return false;
  }
  if (responseStatus === RESPONSE_STATUSES.PERMISSION_DENIED) {
    notify({
      text: "Permission denied",
      type: MESSAGE_TYPES.error,
      toastId: uuidv1(),
    });
  }
  return true;
};
export const noop = (): void => {};

export const getAvailablePlanningYears = (years: number): TSelectOptions => {
  return Array.from({ length: years }, (_, i) => {
    const year = new Date().getFullYear() + i;
    return {
      value: year,
      label: `${year}`,
    };
  });
};

export const getUnitNameFromLocalization = (
  localizations: TLocaliziedUnitName[] | null,
  langCode = ACTIVE_LANG_CODE
): string => {
  if (!localizations || localizations.length === 0) {
    return "";
  }
  const value = localizations.find((l) => l.language === langCode);
  return value ? value.name : "";
};
export const getCompanyNameFromLocalization = (
  localizations: TLocaliziedCompanyName[] | null,
  langCode = ACTIVE_LANG_CODE
): string => {
  if (!localizations || localizations.length === 0) {
    return "";
  }
  const value = localizations.find((l) => l.language === langCode);
  return value ? value.unitName : "";
};
export const getNameFromLocalization = (
  localizations: TLocaliziedEmployeeName[] | TLocaliziedUnitName[] | null,
  langCode = ACTIVE_LANG_CODE
): string => {
  if (!localizations || localizations.length === 0) {
    return "";
  }
  const value = localizations.find((l) => l.language === langCode);
  return value ? value.name : "";
};

export const generateCompanyOptions = (
  companies: TCompany[]
): TSelectOptions => {
  if (!companies) return [];
  return companies
    .filter((c) => c)
    .map((company) => ({
      label: getUnitNameFromLocalization(company.labels),
      value: company.value,
    }));
};
export const generateUnitOptions = (companies: TCompany[]): TSelectOptions => {
  if (!companies) return [];
  return companies
    .filter((c) => c)
    .map((company) => ({
      label: getUnitNameFromLocalization(company.labels),
      value: company.value,
    }));
};
export const generateEmployeeOptions = (
  companies: TCompany[]
): TSelectOptions => {
  if (!companies) return [];
  return companies
    .filter((c) => c)
    .map((company) => ({
      label: getUnitNameFromLocalization(company.labels),
      value: company.value,
    }));
};

export const fixTimeZone = (isoTimeString: string | null) => {
  if (!isoTimeString) {
    return "";
  }
  const originalTime = dayjs(isoTimeString);
  return originalTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};

export const combineDateTime = (date: Date, time: Date) => {
  const a = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  );

  return fixTimeZone(a.toISOString());
};

export const getCycleStatus = (statusValue: number | string) => {
  return CYCLE_STATUSES.find(
    (status: TCycleStatus) => status.value == statusValue
  );
};

export const getExcludeAssociateOptions = (employees: TExcludedAssociate[]) => {
  return employees?.map(({ associate }) => {
    return {
      label: getNameFromLocalization(associate.labels),
      value: associate.value,
    };
  });
};

export const getExcludeUnitOptions = (units: TExcludedUnit[]) => {
  return units?.map(({ unit }) => {
    return {
      label: getUnitNameFromLocalization(unit.labels),
      value: unit.value,
    };
  });
};

export const getStringWidth = (text: string, fontSize: number): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = `${fontSize}px Arial`;

  return context.measureText(text).width;
};

export const getNextDay = (date = new Date()) => {
  const tomorrow = new Date();
  tomorrow.setDate(date.getDate() + 1);
  return tomorrow;
};
