import { httpClient } from "services/http";
import {
  GET_COMPANIES,
  GET_COMPANY_EMPLOYEES,
  GET_COMPANY_UNITS,
  GET_CYCLES,
  GET_CYCLE_DETAILS,
  SET_CYCLE,
  UPDATE_CYCLE,
} from "./endpoints";
import { TPatchPayload, TSetCyclePayload } from "utils/types";

export const getCyclesApi = async (): Promise<any> => {
  return await httpClient.get(GET_CYCLES);
};
export const getCompaniesApi = async (): Promise<any> => {
  return await httpClient.get(GET_COMPANIES);
};
export const getCompanyUnitsApi = async (id: number): Promise<any> => {
  return await httpClient.get(`${GET_COMPANY_UNITS}?companyId=${id}`);
};
export const getCompanyEmployeesApi = async (id: number): Promise<any> => {
  return await httpClient.get(`${GET_COMPANY_EMPLOYEES}?companyId=${id}`);
};

export const getCycleDetailsApi = async (cycleId: number): Promise<any> => {
  return await httpClient.get(`${GET_CYCLE_DETAILS}?id=${cycleId}`);
};

export const setCycleApi = async (payload: TSetCyclePayload): Promise<any> => {
  return await httpClient.post(SET_CYCLE, payload);
};
export const updateCycleApi = async (
  payload: TPatchPayload,
  companyId: number | string
): Promise<any> => {
  return await httpClient.patch(`${UPDATE_CYCLE}?id=${companyId}`, payload);
};
