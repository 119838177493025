import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";

import { checkAuthorization, noop } from "utils/helpers";
import { RESPONSE_STATUSES } from "utils/enums";

declare module "axios" {}

type TExcludedFields =
  | "request"
  | "name"
  | "message"
  | "config"
  | "code"
  | "stack"
  | "isAxiosError"
  | "toJSON";

type TErrorHandlerArguments = Omit<AxiosError, TExcludedFields>;

interface IErrorHandler {
  (args: TErrorHandlerArguments): void;
}
export abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected errorHandlerCallBack: IErrorHandler;

  public constructor(baseURL: string, errorHandler?: IErrorHandler) {
    this.instance = axios.create({
      baseURL,
    });

    this.errorHandlerCallBack = errorHandler || noop;

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor(): void {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError.bind(this)
    );
    this.instance.interceptors.request.use(this.handleRequest.bind(this));
  }

  private async handleRequest(
    config: AxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    const requestConfig: InternalAxiosRequestConfig = {
      ...config,
      headers: (config.headers as AxiosRequestHeaders) ?? {},
    };

    return requestConfig;
  }

  private handleResponse(response: AxiosResponse<any, any>): AxiosResponse {
    return response;
  }

  protected handleError(error: AxiosError): any {
    checkAuthorization(error.response?.status as RESPONSE_STATUSES);

    this.errorHandlerCallBack?.({ response: error.response });

    return Promise.reject(error.response);
  }
}
