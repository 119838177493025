import React from "react";
import { FormField } from "hrm_ui_lib/components/FormField";
import { Text } from "hrm_ui_lib/components/Text";
import {
  FORM_FIELD_LABEL,
  FORM_FIELD_NAME,
  FREQUENCY_OPTIONS,
} from "../consts";
import { Switcher } from "hrm_ui_lib/components/Switcher";
import { useTranslation } from "react-i18next";
import { SimpleDatePicker, TimePicker } from "hrm_ui_lib/components/DatePicker";
import { useFormProps } from "hrm_ui_lib/hooks/useFormProps";
import { Divider } from "hrm_ui_lib/components/Divider";
import { MultiSelect, Select } from "hrm_ui_lib/components/Select";
import {
  generateEmployeeOptions,
  generateUnitOptions,
  getNextDay,
} from "utils/helpers";
import { useContainerData } from "hooks";
import { DATE_PICKER_FORMAT } from "utils/constants";

export const MailRemindersEdit = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormProps();
  const companyEmployees = useContainerData("companyEmployees");
  const companyUnits = useContainerData("companyUnits");

  const onEmployeeReminderChange = (isChecked: boolean) => {
    if (!isChecked) {
      setValue(FORM_FIELD_NAME.employeeRemindStartDate, null);
      setValue(FORM_FIELD_NAME.employeeRemindFrequency, null);
      setValue(FORM_FIELD_NAME.employeeRemindTime, null);
      setValue(FORM_FIELD_NAME.excludeEmployees, []);
    }
  };
  const onManagerReminderChange = (isChecked: boolean) => {
    if (!isChecked) {
      setValue(FORM_FIELD_NAME.managerRemindStartDate, null);
      setValue(FORM_FIELD_NAME.managerRemindFrequency, null);
      setValue(FORM_FIELD_NAME.managerRemindTime, null);
      setValue(FORM_FIELD_NAME.excludeManagers, []);
    }
  };

  return (
    <>
      <FormField
        className="full-width"
        name={FORM_FIELD_NAME.employeeReminder}
        As={(props) => (
          <Switcher
            {...props}
            size="small"
            disabled={watch && !watch(FORM_FIELD_NAME.cycleStartDate)}
            onClick={onEmployeeReminderChange}
            label={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeReminder]}
          />
        )}
      />
      {watch && watch(FORM_FIELD_NAME.employeeReminder) ? (
        <>
          <Text>{t("fields.employee_remind_hint_text")}</Text>
          <FormField
            name={FORM_FIELD_NAME.employeeRemindStartDate}
            As={(props) => {
              return (
                <SimpleDatePicker
                  {...props}
                  size="small"
                  required
                  minDate={getNextDay()}
                  maxDate={watch(FORM_FIELD_NAME.cycleEndDate)}
                  format={DATE_PICKER_FORMAT}
                  label={
                    FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindStartDate]
                  }
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.employeeRemindFrequency}
            As={(props) => {
              return (
                <Select
                  {...props}
                  size="small"
                  isRequiredField
                  label={
                    FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindFrequency]
                  }
                  options={FREQUENCY_OPTIONS}
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.employeeRemindTime}
            As={(props) => {
              return (
                <TimePicker
                  {...props}
                  size="small"
                  required
                  label={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindTime]}
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.excludeEmployees}
            As={(props) => {
              return (
                <MultiSelect
                  {...props}
                  size="small"
                  isLoading={companyEmployees?.isLoading}
                  options={generateEmployeeOptions(companyEmployees?.data)}
                  disabled={watch && !watch(FORM_FIELD_NAME.company)}
                  label={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeEmployees]}
                />
              );
            }}
          />
        </>
      ) : null}
      <Divider type="primary" isHorizontal />
      <FormField
        name={FORM_FIELD_NAME.managerReminder}
        As={(props) => (
          <Switcher
            {...props}
            size="small"
            disabled={watch && !watch(FORM_FIELD_NAME.cycleStartDate)}
            onClick={onManagerReminderChange}
            label={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerReminder]}
          />
        )}
      />
      {watch && watch(FORM_FIELD_NAME.managerReminder) ? (
        <>
          <Text>{t("fields.employee_remind_hint_text")}</Text>
          <FormField
            name={FORM_FIELD_NAME.managerRemindStartDate}
            As={(props) => {
              return (
                <SimpleDatePicker
                  {...props}
                  size="small"
                  required
                  minDate={getNextDay()}
                  maxDate={watch(FORM_FIELD_NAME.cycleEndDate)}
                  format={DATE_PICKER_FORMAT}
                  label={
                    FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindStartDate]
                  }
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.managerRemindFrequency}
            As={(props) => {
              return (
                <Select
                  {...props}
                  size="small"
                  isRequiredField
                  label={
                    FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindFrequency]
                  }
                  options={FREQUENCY_OPTIONS}
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.managerRemindTime}
            As={(props) => {
              return (
                <TimePicker
                  {...props}
                  size="small"
                  required
                  label={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindTime]}
                />
              );
            }}
          />
          <FormField
            name={FORM_FIELD_NAME.excludeManagers}
            As={(props) => {
              return (
                <MultiSelect
                  {...props}
                  size="small"
                  options={generateUnitOptions(companyUnits?.data)}
                  isLoading={companyUnits?.isLoading}
                  disabled={watch && !watch(FORM_FIELD_NAME.company)}
                  label={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeManagers]}
                />
              );
            }}
          />
        </>
      ) : null}
    </>
  );
};
