import { Progress } from "hrm_ui_lib/components/Progress";
import React, { ReactElement } from "react";
import classNames from "classnames";
import "./style.scss";

export const Loading = ({
  className,
}: {
  className?: string;
}): ReactElement => {
  return (
    <div className={classNames("page-loading", className)}>
      <Progress percent={33} type="circle" loop noText size="small" />
    </div>
  );
};
