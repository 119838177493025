import React, { ReactElement, useCallback } from "react";
import classNames from "classnames";
import { Copy } from "hrm_ui_lib/components/Copy";
import { IconInfo } from "hrm_ui_lib/components/SVGIcons/IconInfo";
import { Text } from "hrm_ui_lib/components/Text";
import { Tooltip } from "hrm_ui_lib/components/Tooltip";
import "./styles.scss";

type TProps = {
  title: string;
  description?: string;
  component?: ReactElement | null;
  tooltip?: { text: string | ReactElement; id: number | string };
  withCopy?: boolean;
  fullWidth?: boolean;
  infoClassName?: string;
};
export const ViewInfoField = ({
  title,
  description,
  component = null,
  withCopy,
  tooltip,
  fullWidth = false,
  infoClassName,
}: TProps) => {
  const getTooltipIcon = useCallback(
    ({ text, id }: { text: string | ReactElement; id: number | string }) => {
      return (
        <div className="info_icon" id={`${id}`}>
          <IconInfo size="small" />
          <Tooltip text={text || ""} id={id} />
        </div>
      );
    },
    []
  );

  return (
    <div key={title} className={classNames("info", { full_width: fullWidth })}>
      <Text size="small" className="info_title">{`${title}`}</Text>
      <div className="info_description">
        {description ? (
          <Text
            weight="bolder"
            className={infoClassName}
          >{`${description}`}</Text>
        ) : (
          "-"
        )}
        {component}
        {tooltip ? getTooltipIcon(tooltip) : null}
        {withCopy && description ? (
          <Copy className="info_icon" text={description} />
        ) : null}
      </div>
    </div>
  );
};
