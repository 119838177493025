import { useEffect, useState } from "react";
import store from "../store/index";

export const useContainerData = (propName: string) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const subscription = store.subscribe((state: any) => {
      setData(state[propName]);
    });
    store.init();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return data;
};
