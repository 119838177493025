import React from "react";
import { MailRemindersEdit } from "./MailRemindersEdit";
import { ActionTypeValue } from "utils/enums";
import { useGetCycleActionMode } from "hooks/useGetCycleActionMode";
import { MailRemindersView } from "./MailRemindersView";

export const MailReminders = () => {
  const sideSheetActionValue = useGetCycleActionMode();
  const isViewCycleMode = sideSheetActionValue === ActionTypeValue.viewCycle;

  return isViewCycleMode ? <MailRemindersView /> : <MailRemindersEdit />;
};
