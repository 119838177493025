import { FORM_FIELD_NAME } from "components/CycleForm/consts";
import { TDirtyFields } from "hrm_ui_lib/components/FormContainer/types";
import { combineDateTime, fixTimeZone } from "utils/helpers";
import {
  TPatchPayload,
  TSetCycleFormData,
  TSetCyclePayload,
} from "utils/types";

export const sanitizeCycleData = (
  data: TSetCycleFormData
): TSetCyclePayload => {
  const {
    company,
    planningYear,
    mandatoryDays,
    cycleStartDate,
    cycleEndDate,
    employeeDeadline,
    managerDeadline,
    adminDeadline,
    employeeReminder,
    employeeRemindStartDate,
    employeeRemindFrequency,
    employeeRemindTime,
    excludeEmployees,
    managerReminder,
    managerRemindStartDate,
    managerRemindFrequency,
    managerRemindTime,
    excludeManagers,
    excludeUnits,
  } = data;

  return {
    unitId: company,
    planningYear,
    mandatoryDays,
    cycleStartDate: fixTimeZone(cycleStartDate),
    cycleEndDate: fixTimeZone(cycleEndDate),
    associateStageEndDate: fixTimeZone(employeeDeadline),
    unitStageEndDate: fixTimeZone(managerDeadline),
    companyStageEndDate: fixTimeZone(adminDeadline),
    unitExclusions: excludeUnits.map((unit) => {
      return { unitId: unit.value };
    }),
    associateReminderConfig: employeeReminder
      ? {
          associateReminderStartDate: combineDateTime(
            new Date(fixTimeZone(employeeRemindStartDate)),
            new Date(fixTimeZone(employeeRemindTime))
          ),

          associateReminderFrequency: employeeRemindFrequency,
          associateReminderExclusions: excludeEmployees?.map((employee) => {
            return { associateId: employee.value };
          }),
        }
      : null,
    managerReminderConfig: managerReminder
      ? {
          managerReminderStartDate: combineDateTime(
            new Date(fixTimeZone(managerRemindStartDate)),
            new Date(fixTimeZone(managerRemindTime))
          ),
          managerReminderFrequency: managerRemindFrequency,
          managerReminderExclusions: excludeManagers?.map((manager) => {
            return { unitId: manager.value };
          }),
        }
      : null,
  };
};

const UPDATE_MAPPING = {
  [FORM_FIELD_NAME.company]: "unitId",
  [FORM_FIELD_NAME.planningYear]: "planningYear",
  [FORM_FIELD_NAME.mandatoryDays]: "mandatoryDays",
  [FORM_FIELD_NAME.excludeUnits]: "unitExclusions",
  [FORM_FIELD_NAME.cycleStartDate]: "cycleStartDate",
  [FORM_FIELD_NAME.cycleEndDate]: "cycleEndDate",
  [FORM_FIELD_NAME.employeeDeadline]: "associateStageEndDate",
  [FORM_FIELD_NAME.managerDeadline]: "unitStageEndDate",
  [FORM_FIELD_NAME.adminDeadline]: "companyStageEndDate",
  [FORM_FIELD_NAME.employeeReminder]: "associateReminderConfig",
  [FORM_FIELD_NAME.employeeRemindStartDate]:
    "associateReminderConfig/associateReminderStartDate",
  [FORM_FIELD_NAME.employeeRemindFrequency]:
    "associateReminderConfig/associateReminderFrequency",
  [FORM_FIELD_NAME.employeeRemindTime]:
    "associateReminderConfig/associateReminderStartDate",
  [FORM_FIELD_NAME.excludeEmployees]:
    "associateReminderConfig/associateReminderExclusions",
  [FORM_FIELD_NAME.managerReminder]: "managerReminderConfig",
  [FORM_FIELD_NAME.managerRemindStartDate]:
    "managerReminderConfig/managerReminderStartDate",
  [FORM_FIELD_NAME.managerRemindFrequency]:
    "managerReminderConfig/managerReminderFrequency",
  [FORM_FIELD_NAME.managerRemindTime]:
    "managerReminderConfig/managerReminderStartDate",
  [FORM_FIELD_NAME.excludeManagers]:
    "managerReminderConfig/ManagerReminderExclusions",
};

export const getCycleUpdatePayload = (
  data: TSetCycleFormData,
  dirtyFields: TDirtyFields
): TPatchPayload => {
  return Object.keys(dirtyFields).reduce((acc: TPatchPayload, key: string) => {
    let value: unknown = data[key as keyof TSetCycleFormData] || null;
    let _key = key;
    if (
      key === FORM_FIELD_NAME.employeeRemindTime ||
      key === FORM_FIELD_NAME.employeeRemindStartDate
    ) {
      value = combineDateTime(
        new Date(fixTimeZone(data.employeeRemindStartDate)),
        new Date(fixTimeZone(data.employeeRemindTime))
      );
      _key = FORM_FIELD_NAME.employeeRemindStartDate;
    }

    if (
      key === FORM_FIELD_NAME.employeeReminder ||
      key === FORM_FIELD_NAME.managerReminder
    ) {
      value = value ? {} : null;
    }

    if (
      key === FORM_FIELD_NAME.managerRemindTime ||
      key === FORM_FIELD_NAME.managerRemindStartDate
    ) {
      value = combineDateTime(
        new Date(fixTimeZone(data.managerRemindStartDate)),
        new Date(fixTimeZone(data.managerRemindTime))
      );
      _key = FORM_FIELD_NAME.managerRemindStartDate;
    }
    if (key === FORM_FIELD_NAME.excludeEmployees) {
      value = data.excludeEmployees?.map(({ value }) => {
        return {
          associateId: value,
        };
      }) as any;
    }
    if (
      key === FORM_FIELD_NAME.excludeManagers ||
      key === FORM_FIELD_NAME.excludeUnits
    ) {
      value = data.excludeManagers?.map(({ value }) => {
        return {
          unitId: value,
        };
      }) as any;
    }

    acc = [
      ...acc,
      {
        op: "replace",
        path: UPDATE_MAPPING[_key],
        value,
      },
    ];
    return acc;
  }, []);
};
