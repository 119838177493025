import React, { useEffect } from "react";
import store from "store";
import { AttentionMessage } from "components";
import { FormContainer } from "hrm_ui_lib/components/FormContainer";
import { AgreementCheckbox } from "./AgreementCheckbox/AgreementCheckbox";
import { CollapseGroup } from "hrm_ui_lib/components/Collapse";
import { TDirtyFields } from "hrm_ui_lib/components/FormContainer/types";
import { FORM_ID } from "utils/constants";
import { DirtyStateSetter, Loading } from "components/common";
import {
  fetchCompaniesData,
  fetchCompanyEmployees,
  fetchCompanyUnits,
  setNewCycle,
  updateCycle,
} from "store/actions";
import { useContainerData } from "hooks";
import { ActionTypeValue } from "utils/enums";
import { useGetCycleActionMode } from "hooks/useGetCycleActionMode";
import { generateInitialValues } from "./helpers";
import {
  CYCLE_COLLAPSE_ITEMS,
  FORM_FIELD_NAME,
  getCycleValidations,
} from "./consts";
import "./styles.scss";

export const CycleForm = () => {
  const cycleActionType = useGetCycleActionMode();
  const isSetCycleMode = cycleActionType === ActionTypeValue.setCycle;

  const cycleDetails = useContainerData("activeCycleData");

  const onSubmit = (data: any, _: any, dirtyFields?: TDirtyFields) => {
    store.setModalData({
      value: isSetCycleMode
        ? ActionTypeValue.confirmSetCycle
        : ActionTypeValue.confirmCycleUpdate,
      data: {
        callback: () =>
          isSetCycleMode ? setNewCycle(data) : updateCycle(data, dirtyFields),
      },
    });
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  useEffect(() => {
    if (cycleDetails?.unitId) {
      fetchCompanyUnits(cycleDetails.unitId);
      fetchCompanyEmployees(cycleDetails.unitId);
    }
  }, [cycleDetails?.unitId]);

  if (!cycleDetails && !isSetCycleMode) return <Loading />;

  return (
    <div className="cycle_form">
      {isSetCycleMode ? <AttentionMessage /> : null}
      <FormContainer
        onSubmit={onSubmit}
        initialValues={
          isSetCycleMode
            ? {
                [FORM_FIELD_NAME.cycleStartDate]: new Date(),
              }
            : generateInitialValues(cycleDetails)
        }
        validationScheme={getCycleValidations(isSetCycleMode)}
        formId={isSetCycleMode ? FORM_ID.setCycle : FORM_ID.editCycle}
      >
        <DirtyStateSetter />
        <CollapseGroup
          items={CYCLE_COLLAPSE_ITEMS}
          titleProps={{ color: "selected" }}
        />
        {isSetCycleMode ? <AgreementCheckbox /> : null}
      </FormContainer>
    </div>
  );
};
