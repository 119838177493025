import React from "react";
import { FormField } from "hrm_ui_lib/components/FormField";
import { Checkbox } from "hrm_ui_lib/components/Checkbox";
import { FORM_FIELD_LABEL, FORM_FIELD_NAME } from "../consts";

export const AgreementCheckbox = () => {
  return (
    <FormField
      name={FORM_FIELD_NAME.agreement}
      As={(props) => (
        <Checkbox
          {...props}
          className="agreement_checkbox mt-8"
          selectedValue={false}
          label={FORM_FIELD_LABEL[FORM_FIELD_NAME.agreement]}
        />
      )}
    />
  );
};
