import { SimpleDatePicker } from "hrm_ui_lib/components/DatePicker";
import { FormField } from "hrm_ui_lib/components/FormField";
import React from "react";
import { FORM_FIELD_LABEL, FORM_FIELD_NAME } from "../consts";
import { useFormProps } from "hrm_ui_lib/hooks/useFormProps";
import { Divider } from "hrm_ui_lib/components/Divider";
import { DATE_PICKER_FORMAT } from "utils/constants";

export const CycleDetailsEdit = () => {
  const { watch, setValue } = useFormProps();
  const onCycleEndDateChange = (date: string) => {
    if (!date) {
      setValue(FORM_FIELD_NAME.cycleEndDate, undefined);
    }
    setValue(FORM_FIELD_NAME.employeeDeadline, undefined);
    setValue(FORM_FIELD_NAME.adminDeadline, undefined);
    setValue(FORM_FIELD_NAME.managerDeadline, undefined);
    setValue(FORM_FIELD_NAME.employeeRemindStartDate, undefined);
    setValue(FORM_FIELD_NAME.managerRemindStartDate, undefined);
  };

  return (
    <>
      <FormField
        name={FORM_FIELD_NAME.cycleStartDate}
        As={(props) => {
          return (
            <SimpleDatePicker
              {...props}
              required
              size="small"
              format={DATE_PICKER_FORMAT}
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.cycleStartDate]}
              minDate={new Date()}
              disabled={true}
            />
          );
        }}
      />

      <FormField
        name={FORM_FIELD_NAME.cycleEndDate}
        As={(props) => {
          return (
            <SimpleDatePicker
              {...props}
              required
              size="small"
              format={DATE_PICKER_FORMAT}
              changeHandler={onCycleEndDateChange}
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.cycleEndDate]}
              disabled={watch && !watch(FORM_FIELD_NAME.cycleStartDate)}
              minDate={watch && watch(FORM_FIELD_NAME.cycleStartDate)}
            />
          );
        }}
      />
      <Divider type="primary" isHorizontal />
      <FormField
        name={FORM_FIELD_NAME.employeeDeadline}
        As={(props) => {
          return (
            <SimpleDatePicker
              {...props}
              required
              size="small"
              format={DATE_PICKER_FORMAT}
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeDeadline]}
              disabled={watch && !watch(FORM_FIELD_NAME.cycleEndDate)}
              minDate={watch && watch(FORM_FIELD_NAME.cycleStartDate)}
              maxDate={watch && watch(FORM_FIELD_NAME.cycleEndDate)}
            />
          );
        }}
      />
      <FormField
        name={FORM_FIELD_NAME.managerDeadline}
        As={(props) => {
          return (
            <SimpleDatePicker
              {...props}
              required
              size="small"
              format={DATE_PICKER_FORMAT}
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerDeadline]}
              disabled={watch && !watch(FORM_FIELD_NAME.employeeDeadline)}
              minDate={watch && watch(FORM_FIELD_NAME.employeeDeadline)}
              maxDate={watch && watch(FORM_FIELD_NAME.cycleEndDate)}
            />
          );
        }}
      />
      <FormField
        name={FORM_FIELD_NAME.adminDeadline}
        As={(props) => {
          return (
            <SimpleDatePicker
              {...props}
              required
              size="small"
              format={DATE_PICKER_FORMAT}
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.adminDeadline]}
              disabled={watch && !watch(FORM_FIELD_NAME.managerDeadline)}
              minDate={watch && watch(FORM_FIELD_NAME.managerDeadline)}
              maxDate={watch && watch(FORM_FIELD_NAME.cycleEndDate)}
            />
          );
        }}
      />
    </>
  );
};
