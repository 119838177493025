import { translate } from "i18n";
import {
  getCompaniesApi,
  getCompanyEmployeesApi,
  getCompanyUnitsApi,
  getCycleDetailsApi,
  getCyclesApi,
  setCycleApi,
  updateCycleApi,
} from "api";
import store from "store";
import { TSetCycleFormData } from "utils/types";
import { sanitizeCycleData, getCycleUpdatePayload } from "./helpers";
import { TDirtyFields } from "hrm_ui_lib/components/FormContainer/types";
import { MESSAGE_TYPES } from "../../utils/enums";

export const fetchCycleData = () => {
  store.setCycleLoading();
  getCyclesApi().then(({ data }) => {
    store.setCycleData(data);
  });
};

export const fetchCompanyUnits = (companId: number) => {
  store.setCompanyUnitsLoadingState();
  getCompanyUnitsApi(companId).then(({ data }) => {
    store.setCompanyUnits(data);
  });
};
export const fetchCompanyEmployees = (companId: number) => {
  store.setCompanyEmployeesLoadingState();
  getCompanyEmployeesApi(companId).then(({ data }) => {
    store.setCompanyEmployees(data);
  });
};

export const fetchCompaniesData = () => {
  const companies = store.getCompanies();
  if (companies) {
    return;
  }
  getCompaniesApi().then(({ data }) => {
    store.setCompaniesData(data);
  });
};

export const fetchCycleDetails = (id: number) => {
  store.clearActiveCycleData();
  getCycleDetailsApi(id).then(({ data }) => {
    store.setActiveCycleData(data);
  });
};

//ACTIONS

export const setNewCycle = (data: TSetCycleFormData) => {
  const payload = sanitizeCycleData(data);
  store.setModalSubmitState(true);
  setCycleApi(payload)
    .then(() => {
      fetchCycleData();
      store.clearModalData();
      store.clearSideSheetData();
      store.clearActiveCycleData();
      store.showNotificationMessage({
        message: translate("successfully_set"),
        type: MESSAGE_TYPES.success,
        id: new Date().getTime(),
      });
    })
    .catch(() => {
      store.clearModalData();
      store.showNotificationMessage({
        message: translate("error_message"),
        type: MESSAGE_TYPES.error,
        id: new Date().getTime(),
      });
    });
};

export const updateCycle = (
  data: TSetCycleFormData,
  dirtyFields: TDirtyFields | undefined
) => {
  const payload = getCycleUpdatePayload(data, dirtyFields || {});
  const { id } = store.getSideSheetActionDate() || {};
  store.setModalSubmitState(true);
  if (!id) {
    return;
  }
  updateCycleApi(payload, id)
    .then(() => {
      fetchCycleData();
      store.clearModalData();
      store.clearSideSheetData();
      store.showNotificationMessage({
        message: translate("successfully_update"),
        type: MESSAGE_TYPES.success,
        id: new Date().getTime(),
      });
      store.clearActiveCycleData();
    })
    .catch(() => {
      store.clearModalData();
      store.showNotificationMessage({
        message: translate("error_message"),
        type: MESSAGE_TYPES.error,
        id: new Date().getTime(),
      });
    });
};
