import { useEffect } from "react";
import store from "store";
import { useFormProps } from "hrm_ui_lib/hooks/useFormProps";
import { useContainerData } from "hooks";

export const DirtyStateSetter = () => {
  const isFormStateDirty = useContainerData("isFormDirty");
  const { isDirty } = useFormProps();

  useEffect(() => {
    if (isDirty !== undefined && isDirty !== isFormStateDirty) {
      store.setFormDirtyState(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    return () => {
      store.setFormDirtyState(false);
    };
  }, []);

  return null;
};
