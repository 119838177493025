import React, { ReactElement } from "react";
import { MultiSelect, Select } from "hrm_ui_lib/components/Select";
import { FormField } from "hrm_ui_lib/components/FormField";
import { Divider } from "hrm_ui_lib/components/Divider";
import {
  FORM_FIELD_LABEL,
  FORM_FIELD_NAME,
  PLANNING_YEARS_OPTIONS,
} from "../consts";
import { Input } from "hrm_ui_lib/components/Input";
import { useFormProps } from "hrm_ui_lib/hooks/useFormProps";
import { useContainerData } from "hooks";
import { generateCompanyOptions, generateUnitOptions } from "utils/helpers";
import { fetchCompanyEmployees, fetchCompanyUnits } from "store/actions";

export const PlanningDetailsEdit = (): ReactElement => {
  const { watch, setValue } = useFormProps();
  const companies = useContainerData("companies");
  const companyUnits = useContainerData("companyUnits");

  const onCompanySelect = (id: number) => {
    fetchCompanyUnits(id);
    fetchCompanyEmployees(id);
    setValue(FORM_FIELD_NAME.excludeUnits, [], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(FORM_FIELD_NAME.excludeEmployees, [], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(FORM_FIELD_NAME.excludeManagers, [], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <>
      <FormField
        name={FORM_FIELD_NAME.company}
        As={(props) => (
          <Select
            {...props}
            size="small"
            isRequiredField
            setSelectedItem={onCompanySelect}
            label={FORM_FIELD_LABEL[FORM_FIELD_NAME.company]}
            options={generateCompanyOptions(companies)}
          />
        )}
      />
      <FormField
        name={FORM_FIELD_NAME.planningYear}
        As={(props) => {
          return (
            <Select
              {...props}
              size="small"
              isRequiredField
              label={FORM_FIELD_LABEL[FORM_FIELD_NAME.planningYear]}
              options={PLANNING_YEARS_OPTIONS}
            />
          );
        }}
      />
      <FormField
        name={FORM_FIELD_NAME.mandatoryDays}
        As={(props) => (
          <Input
            {...props}
            size="small"
            required
            type="number"
            min={0}
            max={100}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            label={FORM_FIELD_LABEL[FORM_FIELD_NAME.mandatoryDays]}
          />
        )}
      />
      <Divider isHorizontal type="primary" />
      <FormField
        name={FORM_FIELD_NAME.excludeUnits}
        As={(props) => (
          <MultiSelect
            {...props}
            size="small"
            isLoading={companyUnits?.isLoading}
            disabled={watch && !watch(FORM_FIELD_NAME.company)}
            label={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeUnits]}
            options={generateUnitOptions(companyUnits?.data)}
          />
        )}
      />
    </>
  );
};
