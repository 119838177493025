import React from "react";
import { CycleDetailsEdit } from "./CycleDetailsEdit";
import { ActionTypeValue } from "utils/enums";
import { useGetCycleActionMode } from "hooks/useGetCycleActionMode";
import { CycleDetailsView } from "./CycleDetailsView";

export const CycleDetails = () => {
  const sideSheetActionValue = useGetCycleActionMode();
  const isViewMode = sideSheetActionValue === ActionTypeValue.viewCycle;

  return isViewMode ? <CycleDetailsView /> : <CycleDetailsEdit />;
};
