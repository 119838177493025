export enum USER_ROLE_IDS {
  admin = "1",
  user = "2",
}

export enum ActionType {
  modal = "modal",
  sidesheet = "sideSheet",
}

export enum RESPONSE_STATUSES {
  UNAUTHORIZED_STATUS_CODE = 401,
  PERMISSION_DENIED = 403,
}

export enum MESSAGE_TYPES {
  success = "success",
  error = "error",
  warning = "warning",
  information = "information",
}

export enum CYCLE_COLUMNS_KEYS {
  company = "company",
  planningYear = "planningYear",
  startDate = "startDate",
  endDate = "endDate",
  mandatoryDays = "mandatoryDays",
  status = "status",
  actions = "actions",
}

export enum ActionTypeValue {
  editCycle = "editCycle",
  viewCycle = "viewCycle",
  setCycle = "setCycle",
  confirmAction = "confirmAction",
  keepOrDiscard = "keepOrDiscard",
  confirmCycleUpdate = "confirmCycleUpdate",
  confirmSetCycle = "confirmSetCycle",
}

export enum CYCLE_STATUS_IDS {
  active = 0,
  finished = 1,
}
