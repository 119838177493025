import React, { useEffect, useMemo, useRef } from "react";
import store from "store";
import { SideSheet } from "hrm_ui_lib/components/SideSheet";
import { useContainerData } from "hooks";
import { ComponentMapping } from "./consts";
import { CycleForm } from "components/CycleForm";
import { ActionTypeValue } from "utils/enums";

export const CycleViewSidesheet = () => {
  const isFormStateDirty = useContainerData("isFormDirty");
  const sideSheet = useContainerData("sideSheet");
  const sideSheetActionValue = sideSheet?.action?.value;
  const isOpen = !!sideSheetActionValue;
  const _isDirtyRef = useRef<boolean>(isFormStateDirty);

  const _footerButtonProps = useMemo(() => {
    if (!sideSheetActionValue) {
      return;
    }

    const { footerButtons } = ComponentMapping[sideSheetActionValue] || {};
    if (footerButtons) {
      return {
        ...footerButtons,
        confirm: {
          ...footerButtons.confirm,
          // isLoading: isSubmitting,
          disabled: !isFormStateDirty,
        },
      };
    }
  }, [sideSheetActionValue, isFormStateDirty]);

  const closeCallBack = () => {
    store.clearSideSheetData();
    store.clearModalData();
  };

  useEffect(() => {
    _isDirtyRef.current = isFormStateDirty;
  }, [isFormStateDirty]);

  const closeSideSheet = () => {
    if (_isDirtyRef.current) {
      store.setModalData({
        value: ActionTypeValue.keepOrDiscard,
        data: {
          callback: closeCallBack,
        },
      });
    } else {
      store.clearSideSheetData();
    }
  };

  const { className = "", title = "" } = sideSheetActionValue
    ? ComponentMapping[sideSheetActionValue]
    : {};

  return (
    <SideSheet
      isOpen={isOpen}
      onClose={closeSideSheet}
      title={title}
      className={className}
      shouldRemoveCallback={_isDirtyRef.current}
      footerButtons={_footerButtonProps}
      scrollToTopOptions={{
        onPixel: 200,
      }}
    >
      <CycleForm />
    </SideSheet>
  );
};
