import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "hrm_ui_lib/components/Table";
import { Empty } from "hrm_ui_lib/components/Empty";
import store from "store";
import { useContainerData } from "hooks";
import { Loading } from "components/common";
import { CYCLE_COLUMNS } from "utils/constants";
import no_content from "utils/assets/no_content.svg";
import {
  ActionTypeValue,
  CYCLE_COLUMNS_KEYS,
  CYCLE_STATUS_IDS,
} from "utils/enums";
import { CycleActions } from "./Cells";
import "./styles.scss";
import { TCycle } from "utils/types";
import { getCompanyNameFromLocalization } from "utils/helpers";
import { fetchCycleDetails } from "../../store/actions";
import { formatCycleDates } from "components/CycleForm/helpers";
import { CycleCell } from "./Cells/CycleCell";
import { StatusCell } from "./Cells/StatusCell";

export const CycleTable = () => {
  const { t } = useTranslation();

  const cycleState = useContainerData("cycles");
  const { isLoading, data = null } = cycleState || {};

  const setCycleViewMode = (id: number) => () => {
    fetchCycleDetails(id);
    store.setSidesheetData({
      value: ActionTypeValue.viewCycle,
      data: {},
    });
  };

  const tableData = useMemo(() => {
    if (!data) return [];

    return data?.map((cycle: TCycle) => {
      return {
        [CYCLE_COLUMNS_KEYS.company]: (
          <CycleCell
            info={getCompanyNameFromLocalization(cycle.companyName)}
            onClick={setCycleViewMode(cycle.id)}
          />
        ),
        [CYCLE_COLUMNS_KEYS.planningYear]: (
          <CycleCell info={cycle.year} onClick={setCycleViewMode(cycle.id)} />
        ),
        [CYCLE_COLUMNS_KEYS.startDate]: (
          <CycleCell
            info={formatCycleDates(cycle.cycleStartDate, "DD/MM")}
            onClick={setCycleViewMode(cycle.id)}
          />
        ),
        [CYCLE_COLUMNS_KEYS.endDate]: (
          <CycleCell
            info={formatCycleDates(cycle.cycleEndDate, "DD/MM")}
            onClick={setCycleViewMode(cycle.id)}
          />
        ),
        [CYCLE_COLUMNS_KEYS.mandatoryDays]: (
          <CycleCell
            info={cycle.mandatoryDays}
            onClick={setCycleViewMode(cycle.id)}
          />
        ),
        [CYCLE_COLUMNS_KEYS.status]: (
          <StatusCell
            status={cycle.configStatus}
            onClick={setCycleViewMode(cycle.id)}
          />
        ),
        // [CYCLE_COLUMNS_KEYS.actions]: (
        //   <CycleActions
        //     isActive={cycle.configStatus === CYCLE_STATUS_IDS.active}
        //     id={cycle.id}
        //   />
        // ),
      };
    });
  }, [data]);

  if (!data || isLoading)
    return (
      <div className="loading_container">
        <Loading />
      </div>
    );

  if (data.length === 0)
    return <Empty mainMessage={t("no_results")} illustration={no_content} />;

  return (
    <div className="cycle_table">
      <Table columns={CYCLE_COLUMNS} data={tableData} />
    </div>
  );
};
