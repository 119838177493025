import { HttpClient } from "services/apiService";
import { ResponseType } from "axios";

const baseURL: string = location.origin;

class HttpService extends HttpClient {
  public constructor() {
    super(baseURL);
  }

  public async get<T>(
    url: string,
    params: { [key: string]: string | number | null | boolean } = {},
    responseType?: ResponseType
  ): Promise<T> {
    return this.instance.get(url, { params, responseType });
  }

  public post<T, P>(url: string, payload?: P): Promise<T> {
    return this.instance.post(url, payload);
  }

  public update<T, P>(url: string, payload: P): Promise<T> {
    return this.instance.put(url, payload);
  }

  public patch<T, P>(url: string, payload: P): Promise<T> {
    return this.instance.patch(url, payload);
  }
  public put<T, P>(url: string, payload?: P, params?: P): Promise<T> {
    return this.instance.put(url, payload, { params });
  }

  public delete<T>(url: string): Promise<T> {
    return this.instance.delete(url);
  }
}

const httpClient = new HttpService();
export { httpClient };
