import { TButtonPropTypes } from "hrm_ui_lib/components/Button/types";
import { translate } from "i18n";
import {
  ActionTypeValue,
  CYCLE_COLUMNS_KEYS,
  CYCLE_STATUS_IDS,
} from "utils/enums";
import { TCycleStatus } from "utils/types";

export const DATE_PICKER_FORMAT = "DD/MM/YYYY";

export const ROUTE_CONFIGS = {
  main: {
    baseRoute: "",
    companiesPlanning: "/companies-planning",
    employeePlanning: "/my-planning",
    unitPlanning: "/unit-planning",
  },
  nested: {
    employeePlanning: "/employee-planning",
    unitPlanning: "/unit-planning",
    companyPlanning: "/company-planning",
  },
  authorization: {
    login: `/auth/login`,
    logout: `/auth/logout`,
  },
};

export const CYCLE_COLUMNS = [
  {
    Header: translate("company"),
    accessor: CYCLE_COLUMNS_KEYS.company,
  },
  {
    Header: translate("planningYear"),
    accessor: CYCLE_COLUMNS_KEYS.planningYear,
  },
  {
    Header: translate("startDate"),
    accessor: CYCLE_COLUMNS_KEYS.startDate,
  },
  {
    Header: translate("endDate"),
    accessor: CYCLE_COLUMNS_KEYS.endDate,
  },
  {
    Header: translate("mandatoryDays"),
    accessor: CYCLE_COLUMNS_KEYS.mandatoryDays,
  },
  {
    Header: translate("status"),
    accessor: CYCLE_COLUMNS_KEYS.status,
  },
  // {
  //   Header: translate("actions"),
  //   accessor: CYCLE_COLUMNS_KEYS.actions,
  // },
];

export const EDIT_CYELE_FOOTER_BUTTONS: {
  cancel: TButtonPropTypes;
  confirm: TButtonPropTypes;
} = {
  cancel: {
    buttonText: translate("buttons.cancel"),
  },
  confirm: {
    buttonText: translate("buttons.apply"),
    formId: "edit-cycle-form",
    buttonActionType: "submit",
  },
};

export const FORM_ID = {
  [ActionTypeValue.setCycle]: "set_cycle_form",
  [ActionTypeValue.editCycle]: "edit_cycle_form",
};

export const ACTIVE_LANG_CODE = "en-US";

export const CYCLE_STATUSES: TCycleStatus[] = [
  {
    label: translate("statuses.active"),
    value: CYCLE_STATUS_IDS.active,
    type: "information",
  },
  {
    label: translate("statuses.finished"),
    value: CYCLE_STATUS_IDS.finished,
    type: "primary",
  },
];
