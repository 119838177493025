import React from "react";
import { PlanningDetailsEdit } from "./PlanningDetailsEdit";
import { ActionTypeValue } from "utils/enums";
import { useGetCycleActionMode } from "hooks/useGetCycleActionMode";
import { PlanningDetailsView } from "./PlanningDetailsView";

export const PlanningDetails = () => {
  const sideSheetActionValue = useGetCycleActionMode();
  const isSetMode = sideSheetActionValue === ActionTypeValue.setCycle;

  return isSetMode ? <PlanningDetailsEdit /> : <PlanningDetailsView />;
};
