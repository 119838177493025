import React, { useEffect } from "react";
import { Snackbar } from "hrm_ui_lib/components/Snackbar";
import { notify } from "hrm_ui_lib/components/Snackbar";
import store from "store";
import { useContainerData } from "hooks";

export const SnackbarWrapper = () => {
  const infoMessages = useContainerData("notificationMessages");

  useEffect(() => {
    if (infoMessages?.length) {
      const { id, message, type } = infoMessages[infoMessages.length - 1];
      notify({
        text: message,
        type,
        toastId: id,
        closeSnackbar: (_id: number | string) =>
          store.removeNotificationMessage(_id),
      });
    }
  }, [infoMessages]);

  return <Snackbar position="bottom-center" />;
};
