import {
  TCycleDetails,
  TExcludedAssociate,
  TExcludedUnit,
  TExcludedUnitOption,
  TLocaliziedUnitName,
} from "utils/types";
import { FORM_FIELD_NAME, FREQUENCY_OPTIONS } from "./consts";
import dayjs from "dayjs";
import {
  getNameFromLocalization,
  getExcludeAssociateOptions,
  getExcludeUnitOptions,
  getUnitNameFromLocalization,
} from "utils/helpers";

export const getReminderFrequencyOption = (frequency: string | number) => {
  const _frequency = FREQUENCY_OPTIONS.find((f) => f.value == frequency);
  return _frequency ? _frequency.label : "-";
};

const getExcludedUnitOptions = (units: TExcludedUnitOption[]) => {
  return units?.map(({ value, labels }) => {
    return {
      label: getNameFromLocalization(labels),
      value: value,
    };
  });
};

export const generateInitialValues = (cycleDetails: TCycleDetails) => {
  return {
    [FORM_FIELD_NAME.company]: cycleDetails.unitId,
    [FORM_FIELD_NAME.planningYear]: cycleDetails.planningYear,
    [FORM_FIELD_NAME.mandatoryDays]: cycleDetails.mandatoryDays,
    [FORM_FIELD_NAME.excludeUnits]: getExcludedUnitOptions(
      cycleDetails.unitExclusions
    ),
    [FORM_FIELD_NAME.cycleStartDate]: new Date(cycleDetails.cycleStartDate),
    [FORM_FIELD_NAME.cycleEndDate]: new Date(cycleDetails.cycleEndDate),
    [FORM_FIELD_NAME.employeeDeadline]: new Date(
      cycleDetails.associateStageEndDate
    ),
    [FORM_FIELD_NAME.managerDeadline]: new Date(cycleDetails.unitStageEndDate),
    [FORM_FIELD_NAME.adminDeadline]: new Date(cycleDetails.companyStageEndDate),
    [FORM_FIELD_NAME.employeeReminder]: !!cycleDetails.associateReminderConfig,
    [FORM_FIELD_NAME.employeeRemindStartDate]:
      cycleDetails.associateReminderConfig
        ? new Date(
            cycleDetails.associateReminderConfig.associateReminderStartDate
          )
        : null,
    [FORM_FIELD_NAME.employeeRemindFrequency]: FREQUENCY_OPTIONS[0].value,

    [FORM_FIELD_NAME.employeeRemindTime]: cycleDetails.associateReminderConfig
      ? new Date(
          cycleDetails.associateReminderConfig.associateReminderStartDate
        )
      : null,
    [FORM_FIELD_NAME.excludeEmployees]: cycleDetails.associateReminderConfig
      ? getExcludeAssociateOptions(
          cycleDetails.associateReminderConfig.associateReminderExclusions
        )
      : [],
    [FORM_FIELD_NAME.managerReminder]: !!cycleDetails.managerReminderConfig,
    [FORM_FIELD_NAME.managerRemindStartDate]: cycleDetails.managerReminderConfig
      ? new Date(cycleDetails.managerReminderConfig.managerReminderStartDate)
      : null,
    [FORM_FIELD_NAME.managerRemindFrequency]: FREQUENCY_OPTIONS[0].value,
    [FORM_FIELD_NAME.managerRemindTime]: cycleDetails.managerReminderConfig
      ? new Date(cycleDetails.managerReminderConfig.managerReminderStartDate)
      : null,
    [FORM_FIELD_NAME.excludeManagers]: cycleDetails.managerReminderConfig
      ? getExcludeUnitOptions(
          cycleDetails.managerReminderConfig.managerReminderExclusions
        )
      : [],
  };
};

export const formatCycleDates = (
  value: string,
  format: string = "DD/MM/YYYY"
) => {
  return dayjs(value).format(format);
};

export const getExcludeUnitLabels = (
  units: {
    value: number;
    labels: TLocaliziedUnitName[];
  }[]
) => {
  return units
    ?.map(({ labels }) => getUnitNameFromLocalization(labels))
    .join(", ");
};

export const getExcludeAssociatesLabels = (list: TExcludedAssociate[]) => {
  return list
    ?.map(({ associate }) => getUnitNameFromLocalization(associate.labels))
    .join(", ");
};

export const getExcludeManagerUnitLabels = (list: TExcludedUnit[]) => {
  return list
    ?.map(({ unit }) => getUnitNameFromLocalization(unit.labels))
    .join(", ");
};
