import React from "react";
import { Text } from "hrm_ui_lib/components/Text";
import { translate } from "i18n";
import { ActionTypeValue } from "utils/enums";
import { TButtonPropTypes } from "hrm_ui_lib/components/Button/types";

export const ModalComponents: {
  [key: string]: {
    component: () => JSX.Element | null;
    title: string;
    buttonProps?: {
      confirm: TButtonPropTypes;
      cancel: TButtonPropTypes;
    };
  };
} = {
  [ActionTypeValue.keepOrDiscard]: {
    title: translate("modal.discard_title"),
    component: () => <Text>{translate("modal.discard_confirm")}</Text>,
    buttonProps: {
      confirm: {
        buttonText: translate("buttons.discard"),
        type: "danger",
      },
      cancel: {
        buttonText: translate("buttons.keep"),
        type: "tertiary",
      },
    },
  },
  [ActionTypeValue.confirmSetCycle]: {
    title: translate("modal.save_title"),
    component: () => <Text>{translate("modal.save_confirm")}</Text>,
    buttonProps: {
      confirm: {
        buttonText: translate("buttons.yes"),
        type: "primary",
      },
      cancel: {
        buttonText: translate("buttons.no"),
        type: "tertiary",
      },
    },
  },
  [ActionTypeValue.confirmCycleUpdate]: {
    title: translate("modal.edit_title"),
    component: () => <Text>{translate("modal.edit_confirm")}</Text>,
    buttonProps: {
      confirm: {
        buttonText: translate("buttons.yes"),
        type: "primary",
      },
      cancel: {
        buttonText: translate("buttons.no"),
        type: "tertiary",
      },
    },
  },
};
