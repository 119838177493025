import React from "react";

import { Button } from "hrm_ui_lib/components/Button";
import store from "store";
import { ActionTypeValue } from "utils/enums";

export const SetCycleButton = () => {
  const openSetCycleSidesheet = () => {
    store.setSidesheetData({
      value: ActionTypeValue.setCycle,
      data: {},
    });
  };

  return <Button buttonText="Set new cycle" onClick={openSetCycleSidesheet} />;
};
