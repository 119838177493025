import { Status } from "hrm_ui_lib/components/Status";
import React from "react";
import { getCycleStatus } from "utils/helpers";

export const StatusCell = ({
  status,
  onClick,
}: {
  status: number;
  onClick: () => void;
}) => {
  return (
    <div className="full_size_cell" onClick={onClick}>
      <Status
        text={getCycleStatus(status)?.label}
        withCircle={false}
        type={getCycleStatus(status)?.type}
      />
    </div>
  );
};
