import React from "react";
import { Text } from "hrm_ui_lib/components/Text";
import "./styles.scss";

export const CycleCell = ({
  info,
  onClick,
}: {
  info: string | number;
  onClick: () => void;
}) => {
  return (
    <Text onClick={onClick} className="full_size_cell">
      {info}
    </Text>
  );
};
