import React, { ReactElement, Suspense } from "react";
import { Loading } from "components/common";
import { SettingsView } from "pages";
import { CycleViewSidesheet, ModalWrapper, SnackbarWrapper } from "components";
import { GoToWebPage } from "hrm_ui_lib/helperComponents/GoToWebPage/GoToWebPage";
import { useIsMobile } from "hrm_ui_lib/hooks/useGetIsMobile";
import "./index.scss";
import { useTranslation } from "react-i18next";

const App = (): ReactElement => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  if (isMobile) {
    return <GoToWebPage message={t("vp_go_to_web_message")} />;
  }
  return (
    <Suspense fallback={<Loading />}>
      <div className="main-container">
        <SettingsView />
        <ModalWrapper />
        <CycleViewSidesheet />
        <SnackbarWrapper />
      </div>
    </Suspense>
  );
};

export default App;
