import { TButtonPropTypes } from "hrm_ui_lib/components/Button/types";
import { translate } from "i18n";
import { FORM_ID } from "utils/constants";
import { ActionTypeValue } from "utils/enums";

export const ComponentMapping: {
  [key: string]: {
    className?: string;
    footerButtons?: {
      cancel: TButtonPropTypes;
      confirm: TButtonPropTypes;
    };
    headerButtons?: {
      close: TButtonPropTypes;
      pin?: TButtonPropTypes;
      back?: TButtonPropTypes;
    };
    title: string;
  };
} = {
  [ActionTypeValue.setCycle]: {
    title: translate("set_cycle"),
    footerButtons: {
      cancel: {
        buttonText: translate("buttons.cancel"),
      },
      confirm: {
        buttonText: translate("buttons.set"),
        buttonActionType: "submit",
        formId: FORM_ID[ActionTypeValue.setCycle],
      },
    },
  },
  [ActionTypeValue.editCycle]: {
    title: translate("edit_cycle"),
    footerButtons: {
      cancel: {
        buttonText: translate("buttons.cancel"),
      },
      confirm: {
        buttonText: translate("buttons.apply"),
        buttonActionType: "submit",
        formId: FORM_ID[ActionTypeValue.editCycle],
      },
    },
  },
  [ActionTypeValue.viewCycle]: {
    title: translate("view_cycle"),
  },
};
