import React, { useMemo } from "react";
import { Modal } from "hrm_ui_lib/components/Modal";
import { useContainerData } from "hooks";
import { ModalComponents } from "./consts";
import store from "store";
import { ActionTypeValue } from "utils/enums";

export const ModalWrapper = () => {
  const modalData = useContainerData("modal");
  const activeValue = modalData?.action?.value;
  const isSubmitting = modalData?.isActionSubmitting;
  const { component: ModalContent, title = "" } = activeValue
    ? ModalComponents[activeValue]
    : { component: () => null };

  const closeModal = () => {
    store.clearModalData();
  };

  const handleSubmit = () => {
    modalData.action?.data?.callback();
  };
  const _buttonProps = useMemo(() => {
    if (!activeValue || !ModalComponents[activeValue]) {
      return;
    }
    const { buttonProps } = ModalComponents[activeValue] || {};

    if (buttonProps) {
      return {
        ...buttonProps,
        confirm: {
          ...buttonProps.confirm,
          isLoading: isSubmitting,
        },
      };
    }
  }, [activeValue, isSubmitting]);

  return (
    <Modal
      title={title}
      isOpen={!!activeValue}
      onClose={closeModal}
      onSubmit={handleSubmit}
      closeIcon={!_buttonProps}
      buttonProps={_buttonProps}
      closeOnOutsideClick={activeValue !== ActionTypeValue.keepOrDiscard}
    >
      <ModalContent />
    </Modal>
  );
};
