import React from "react";
import { v1 as uuidv1 } from "uuid";
import { ViewInfoField } from "../../common";
import { Divider } from "hrm_ui_lib/components/Divider";
import { FORM_FIELD_LABEL, FORM_FIELD_NAME } from "../consts";
import { Text } from "hrm_ui_lib/components/Text";
import { useTranslation } from "react-i18next";
import { useContainerData } from "../../../hooks";
import {
  formatCycleDates,
  getExcludeAssociatesLabels,
  getExcludeManagerUnitLabels,
  getReminderFrequencyOption,
} from "../helpers";
import { getStringWidth } from "utils/helpers";

export const MailRemindersView = () => {
  const { t } = useTranslation();
  const cycleDetails = useContainerData("activeCycleData");

  if (!cycleDetails) {
    return null;
  }

  return (
    <>
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeReminder]}
        description={cycleDetails.associateReminderConfig ? t("on") : t("off")}
      />
      {cycleDetails.associateReminderConfig ? (
        <>
          <Text>{t("fields.employee_remind_hint_text")}</Text>
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindStartDate]}
            description={formatCycleDates(
              cycleDetails.associateReminderConfig.associateReminderStartDate
            )}
          />
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindFrequency]}
            description={getReminderFrequencyOption(
              cycleDetails.associateReminderConfig.associateReminderFrequency
            )}
          />
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeRemindTime]}
            description={formatCycleDates(
              new Date(
                cycleDetails.associateReminderConfig.associateReminderStartDate
              ).toString(),
              "HH:mm"
            )}
          />
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeEmployees]}
            description={getExcludeAssociatesLabels(
              cycleDetails.associateReminderConfig.associateReminderExclusions
            )}
            tooltip={
              getStringWidth(
                getExcludeAssociatesLabels(
                  cycleDetails.associateReminderConfig
                    .associateReminderExclusions
                ),
                14
              ) > 220
                ? {
                    text: getExcludeAssociatesLabels(
                      cycleDetails.associateReminderConfig
                        .associateReminderExclusions
                    ),
                    id: uuidv1(),
                  }
                : undefined
            }
            infoClassName={`text-truncate exclude_section`}
          />
        </>
      ) : null}
      <Divider isHorizontal type="primary" />

      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerReminder]}
        description={cycleDetails.managerReminderConfig ? t("on") : t("off")}
      />
      {cycleDetails.managerReminderConfig ? (
        <>
          <Text>{t("fields.manager_remind_hint_text")}</Text>
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindStartDate]}
            description={formatCycleDates(
              cycleDetails.managerReminderConfig.managerReminderStartDate
            )}
          />
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindFrequency]}
            description={getReminderFrequencyOption(
              cycleDetails.managerReminderConfig.managerReminderFrequency
            )}
          />
          <ViewInfoField
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerRemindTime]}
            description={formatCycleDates(
              new Date(
                cycleDetails.managerReminderConfig.managerReminderStartDate
              ).toString(),
              "HH:mm"
            )}
          />
          <ViewInfoField
            fullWidth
            title={FORM_FIELD_LABEL[FORM_FIELD_NAME.excludeManagers]}
            description={getExcludeManagerUnitLabels(
              cycleDetails.managerReminderConfig.managerReminderExclusions
            )}
            tooltip={
              getStringWidth(
                getExcludeManagerUnitLabels(
                  cycleDetails.managerReminderConfig.managerReminderExclusions
                ),
                14
              ) > 400
                ? {
                    text: getExcludeManagerUnitLabels(
                      cycleDetails.managerReminderConfig
                        .managerReminderExclusions
                    ),
                    id: uuidv1(),
                  }
                : undefined
            }
            infoClassName={`text-truncate exclude_section`}
          />
        </>
      ) : null}
    </>
  );
};
