import React from "react";
import { translate } from "i18n";
import * as yup from "yup";
import { PlanningDetails } from "./PlanningDetails";
import { CycleDetails } from "./CycleDetails";
import { MailReminders } from "./MailReminders";
import { getAvailablePlanningYears } from "utils/helpers";

export const PLANNING_YEARS_OPTIONS = getAvailablePlanningYears(4);

export const FREQUENCY_OPTIONS = [
  {
    label: translate("every_day"),
    value: "0",
  },
];

export const FORM_FIELD_NAME = {
  company: "company",
  planningYear: "planningYear",
  mandatoryDays: "mandatoryDays",
  excludeUnits: "excludeUnits",
  agreement: "agreement",
  cycleStartDate: "cycleStartDate",
  cycleEndDate: "cycleEndDate",
  employeeDeadline: "employeeDeadline",
  managerDeadline: "managerDeadline",
  adminDeadline: "adminDeadline",
  employeeReminder: "employeeReminder",
  employeeRemindStartDate: "employeeRemindStartDate",
  employeeRemindFrequency: "employeeRemindFrequency",
  employeeRemindTime: "employeeRemindTime",
  excludeEmployees: "excludeEmployees",
  managerReminder: "managerReminder",
  managerRemindStartDate: "managerRemindStartDate",
  managerRemindFrequency: "managerRemindFrequency",
  managerRemindTime: "managerRemindTime",
  excludeManagers: "excludeManagers",
};

export const FORM_FIELD_LABEL = {
  [FORM_FIELD_NAME.company]: translate("fields.company"),
  [FORM_FIELD_NAME.planningYear]: translate("fields.planning_year"),
  [FORM_FIELD_NAME.mandatoryDays]: translate("fields.mandatory_planning_days"),
  [FORM_FIELD_NAME.excludeUnits]: translate("fields.exclude_units"),
  [FORM_FIELD_NAME.agreement]: translate("fields.agreement_message"),
  [FORM_FIELD_NAME.cycleStartDate]: translate("fields.cycle_start_date"),
  [FORM_FIELD_NAME.cycleEndDate]: translate("fields.cycle_end_date"),
  [FORM_FIELD_NAME.employeeDeadline]: translate("fields.employee_deadline"),
  [FORM_FIELD_NAME.managerDeadline]: translate("fields.manager_deadline"),
  [FORM_FIELD_NAME.adminDeadline]: translate("fields.admin_deadline"),
  [FORM_FIELD_NAME.employeeReminder]: translate("fields.employee_reminder"),
  [FORM_FIELD_NAME.employeeRemindStartDate]: translate(
    "fields.remind_start_date"
  ),
  [FORM_FIELD_NAME.employeeRemindFrequency]: translate(
    "fields.remind_frequency"
  ),
  [FORM_FIELD_NAME.employeeRemindTime]: translate("fields.remind_time"),
  [FORM_FIELD_NAME.excludeEmployees]: translate("fields.exclude_employees"),
  [FORM_FIELD_NAME.managerReminder]: translate("fields.manager_reminder"),
  [FORM_FIELD_NAME.managerRemindStartDate]: translate(
    "fields.remind_start_date"
  ),
  [FORM_FIELD_NAME.managerRemindFrequency]: translate(
    "fields.remind_frequency"
  ),
  [FORM_FIELD_NAME.managerRemindTime]: translate("fields.remind_time"),
  [FORM_FIELD_NAME.excludeManagers]: translate("fields.exclude_managers"),
};

export const CYCLE_COLLAPSE_ITEEMS = {
  planningDetails: "planningDetails",
  cycleDetails: "cycleDetails",
  mailReminders: "mailReminders",
};

export const CYCLE_COLLAPSE_ITEMS = [
  {
    title: translate("planning_details"),
    value: CYCLE_COLLAPSE_ITEEMS.planningDetails,
    isOpen: true,
    content: <PlanningDetails />,
  },
  {
    title: translate("cycle_details"),
    value: CYCLE_COLLAPSE_ITEEMS.cycleDetails,
    isOpen: true,
    content: <CycleDetails />,
  },
  {
    title: translate("mail_reminders"),
    value: CYCLE_COLLAPSE_ITEEMS.mailReminders,
    isOpen: true,
    content: <MailReminders />,
  },
];

export const getCycleValidations = (isSetCycleMode: boolean) =>
  yup.object({
    [FORM_FIELD_NAME.company]: yup
      .number()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.planningYear]: yup
      .number()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.mandatoryDays]: yup
      .number()
      .min(1, translate("mandatory_planning_days_validation"))
      .max(100, translate("mandatory_planning_days_validation"))
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.cycleStartDate]: yup
      .date()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.cycleEndDate]: yup
      .date()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.employeeDeadline]: yup
      .date()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.managerDeadline]: yup
      .date()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.adminDeadline]: yup
      .date()
      .required(translate("mandatory_message"))
      .nullable(),
    [FORM_FIELD_NAME.employeeRemindStartDate]: yup
      .date()
      .nullable()
      .when(FORM_FIELD_NAME.employeeReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.employeeRemindFrequency]: yup
      .number()
      .nullable()
      .when(FORM_FIELD_NAME.employeeReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.employeeRemindTime]: yup
      .string()
      .nullable()
      .when(FORM_FIELD_NAME.employeeReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.managerRemindStartDate]: yup
      .string()
      .nullable()
      .when(FORM_FIELD_NAME.managerReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.managerRemindFrequency]: yup
      .string()
      .nullable()
      .when(FORM_FIELD_NAME.managerReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.managerRemindTime]: yup
      .string()
      .nullable()
      .when(FORM_FIELD_NAME.managerReminder, ([shouldRemind], schema) => {
        if (shouldRemind) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
    [FORM_FIELD_NAME.agreement]: yup
      .boolean()
      .oneOf([true], translate("check_required_message"))
      .nullable()
      .when("password", ([_], schema) => {
        if (isSetCycleMode) {
          return schema.required(translate("mandatory_message"));
        }
        return schema;
      }),
  });
