import React from "react";
import { ViewInfoField } from "../../common";
import { FORM_FIELD_LABEL, FORM_FIELD_NAME } from "../consts";
import { Divider } from "hrm_ui_lib/components/Divider";
import { useContainerData } from "../../../hooks";
import { formatCycleDates } from "../helpers";

export const CycleDetailsView = () => {
  const cycleDetails = useContainerData("activeCycleData");

  if (!cycleDetails) {
    return null;
  }

  return (
    <>
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.cycleStartDate]}
        description={formatCycleDates(cycleDetails.cycleStartDate)}
      />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.cycleEndDate]}
        description={formatCycleDates(cycleDetails.cycleEndDate)}
      />
      <Divider isHorizontal type="primary" />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.employeeDeadline]}
        description={formatCycleDates(cycleDetails.associateStageEndDate)}
      />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.managerDeadline]}
        description={formatCycleDates(cycleDetails.unitStageEndDate)}
      />
      <ViewInfoField
        title={FORM_FIELD_LABEL[FORM_FIELD_NAME.adminDeadline]}
        description={formatCycleDates(cycleDetails.companyStageEndDate)}
      />
    </>
  );
};
