import { Subject } from "rxjs";
import { TAction, TCycleDetails, TInitialState, TMessage } from "utils/types";

const subject = new Subject();

const initialState: TInitialState = {
  cycles: { data: [], isLoading: true },
  companies: null,
  companyUnits: { data: null, isLoading: false },
  companyEmployees: { data: null, isLoading: false },
  activeCycleData: null,
  isFormDirty: false,
  navigationData: null,
  sideSheet: {
    action: null,
    isLoading: false,
    isActionSubmitting: false,
  },
  modal: {
    action: null,
    isLoading: false,
    isActionSubmitting: false,
  },
  notificationMessages: [],
};

let state = initialState;

const store = {
  init: () => {
    (state = { ...state }), subject.next(state);
  },
  subscribe: (setState: any) => subject.subscribe(setState),
  getCompanies: () => state.companies,
  getSideSheetActionDate: () => state.sideSheet.action?.data,
  setCycleLoading: () => {
    state = {
      ...state,
      cycles: {
        ...state.cycles,
        isLoading: true,
      },
    };
    subject.next(state);
  },
  setCycleData: (data: any) => {
    state = {
      ...state,
      cycles: {
        data,
        isLoading: false,
      },
    };
    subject.next(state);
  },
  setCompanyUnitsLoadingState: () => {
    state = {
      ...state,
      companyUnits: { ...state.companyUnits, isLoading: true },
    };
    subject.next(state);
  },
  setCompanyEmployeesLoadingState: () => {
    state = {
      ...state,
      companyEmployees: { ...state.companyEmployees, isLoading: true },
    };
    subject.next(state);
  },
  setCompaniesData: (data: any) => {
    state = {
      ...state,
      companies: data,
    };
    subject.next(state);
  },
  setCompanyUnits: (data: any) => {
    state = {
      ...state,
      companyUnits: { data, isLoading: false },
    };
    subject.next(state);
  },
  setCompanyEmployees: (data: any) => {
    state = {
      ...state,
      companyEmployees: { data, isLoading: false },
    };
    subject.next(state);
  },
  setSidesheetData: (action: TAction) => {
    state = {
      ...state,
      sideSheet: {
        ...state.sideSheet,
        action,
      },
    };
    subject.next(state);
  },
  clearSideSheetData: () => {
    state = {
      ...state,
      sideSheet: initialState.sideSheet,
      isFormDirty: false,
    };
    subject.next(state);
  },
  setActiveCycleData: (data: TCycleDetails) => {
    state = {
      ...state,
      activeCycleData: data,
    };
    subject.next(state);
  },
  clearActiveCycleData: () => {
    state = {
      ...state,
      activeCycleData: null,
    };
    subject.next(state);
  },
  setFormDirtyState: (isDirty: boolean) => {
    state = {
      ...state,
      isFormDirty: isDirty,
    };
    subject.next(state);
  },
  setModalData: (action: TAction) => {
    state = {
      ...state,
      modal: {
        ...state.modal,
        action,
      },
    };
    subject.next(state);
  },
  setModalSubmitState: (isActionSubmitting: boolean) => {
    state = {
      ...state,
      modal: {
        ...state.modal,
        isActionSubmitting: isActionSubmitting,
      },
    };
    subject.next(state);
  },
  clearModalData: () => {
    state = {
      ...state,
      modal: initialState.modal,
    };
    subject.next(state);
  },
  showNotificationMessage: (message: TMessage) => {
    state = {
      ...state,
      notificationMessages: [...state.notificationMessages, message],
    };
    subject.next(state);
  },
  removeNotificationMessage: (id: number | string) => {
    state = {
      ...state,
      notificationMessages: state.notificationMessages.filter(
        (m) => m.id !== id
      ),
    };
    subject.next(state);
  },
  initialState,
};

export default store;
