import React from "react";
import { Text } from "hrm_ui_lib/components/Text";
import { Link } from "hrm_ui_lib/components/Link";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const AttentionMessage = () => {
  const { t } = useTranslation();
  return (
    <p className="mb-8">
      <Text type="danger" weight="bold" className="inline_text">
        {t("attention")}
      </Text>
      <Text className="inline_text">{t("attentionMessage")}</Text>
      <Link
        url={`${process.env.REACT_APP_CDN}/settings/calendar`}
        className="pointer_cursor"
      >
        {t("link_message")}
      </Link>
    </p>
  );
};
